<template>
  <SurveyQuestion />
</template>

<script>
import SurveyQuestion from './components/SurveyQuestion'
export default {
  name: 'App',
   components: {
     SurveyQuestion
   },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.background-image {
  width: 45%;
  position: absolute;
  right: 0;
  top: 0;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 100% auto;
  height: 100%;
  max-height: 2000px;
} 
.h-68{
  height: 17rem !important;
}
</style>

