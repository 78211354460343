<template>
  <div class="grid grid-cols-1 content-center flex flex-col sm:flex-col-reverse">
    <div class="m-auto">
      <img class="animate__animated animate__slideInDown" style="width: 150px; height: 150px" src="../assets/images/logo.png" alt="logo" />
    </div>
    <h1 class="text-center text-4xl animate__animated animate__slideInDown">
        You have already submitted this survey
        <br> or 
        <br> this link is expired.
    </h1>
  </div>
</template>

<script>

export default {
  name: 'TokenError',
  components: {
  },
  methods: {
    answerEnter(e) {
      if (e.keyCode == 13) {
        this.submit();
      } else {
        this.error = false;
      }
    },
  },
  created() {
  },
}
</script>

<style>
</style>