<template>
  <div>
    <img class="animate__animated animate__slideInRight" v-if="currentSurveyPoint % 2 == 0" src="../assets/images/hero-case.study.jpg" alt="background-image1" />
    <img class="animate__animated animate__slideInRight" v-if="currentSurveyPoint % 2 == 1" src="../assets/images/hero-voice.png" alt="background-image2" />
  </div>
</template>

<script>

export default {
  name: 'BackgroundImage',
  components: {
  },
  data () {
    return {
      currentSurveyPoint: 0
    }
  }
}
</script>

<style>
</style>