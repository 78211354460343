let getters = {
    getBaseStates(states) {
        return states;
    },
    getAnswers: () => (quesiontId) => {
        
        if (localStorage.getItem('question_id_' + quesiontId) == "null") {
            return "";
        }
        return localStorage.getItem('question_id_' + quesiontId);
    }
};

export default getters;