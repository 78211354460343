let state = {
    questions: [
        {
            question_id: 1,
            answer: "",
        },
        {
            question_id: 2,
            answer: "",
        },
        {
            question_id: 3,
            answer: "",
        },
        {
            question_id: 4,
            answer: "",
        },
        {
            question_id: 6,
            answer: "",
        },
        {
            question_id: 7,
            answer: "",
        },
        {
            question_id: 8,
            answer: "",
        },
        {
            question_id: 9,
            answer: "",
        },
        {
            question_id: 10,
            answer: "",
        },
        {
            question_id: 11,
            answer: "",
        },
        {
            question_id: 12,
            answer: "",
        },
        {
            question_id: 13,
            answer: "",
        },
        {
            question_id: 14,
            answer: "",
        },
        {
            question_id: 15,
            answer: "",
        },
    ],
    token: null,
};

export default state;