<template>
  <div class="grid grid-cols-1 content-center flex flex-col sm:flex-col-reverse">
    <h1 class="text-center md:h-24 text-4xl animate__animated animate__slideInDown">
        Overall, how satisfied were you with 
        <br>your Exetel Project Network Engineer?
    </h1>
    <div class="content-center md:h-68 animate__animated animate__slideInRight mx-auto mt-6 sm:w-10/12 md:w-10/12">
        <div class="flex justify-between">
            <button v-for="i in 10" :key="i" 
                class="py-2 px-2.5 md:px-4 
                    hover:bg-primary hover:text-white
                    active:bg-primary active:text-white 
                    focus:bg-primary focus:text-white
                    border border-primary font-bold animate__animated animate__pulse"
                @dblclick="doubleClick(i)"
                @click="selectAnwser(i)"
                :class="answer == i ? 'bg-primary text-white' : 'bg-transparent text-primary'"  
                >
                {{ i }}
            </button>
            
        </div>
        <div class="flex justify-between mt-2">
            <p>Not at all</p>
            <p>Completely</p>
        </div>
    </div>
    <div class="text-center mt-6">
      <button @click="gotoBack" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse mr-1">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2" />
        Back
      </button>
      <button @click="submit" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse ml-1">
        Next
        <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2" />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SurveyQuestion6',
  components: {
  },
  data () {
    return {
        answer: this.$store.getters.getAnswers(11),
    };
  },
  methods: {
    selectAnwser(value) {
        this.answer = value;
    },
    doubleClick(value) {
        this.answer = value;
        this.submit();
    },
    submit() {
      this.save();
      this.$emit('changeIndex', 7);
    },
    gotoBack() {
      this.save();
      this.$emit('changeIndex', 5);
    },
    save() {
      let data = [];
      data['data'] = {
        question_id: 11,
        answer: this.answer
      };
      this.$store.dispatch('evaluateQuestion', data);
    }
  }
}
</script>

<style>
</style>