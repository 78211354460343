<template>
  <div class="grid grid-cols-1 content-center flex flex-col sm:flex-col-reverse">
    <h1 class="text-center md:h-24 text-4xl animate__animated animate__slideInDown">Do you have any additional feedback?</h1>
    <div class="text-center mt-6 md:h-68">
        <textarea v-model="answer" v-on:keyup="pressEnter" class="resize-none appearance-none w-8/12 bg-transparent border-b border-primary text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"></textarea>
    </div>
    <div class="text-center mt-6">
      <button @click="gotoBack" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse mr-1">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2" />
        Back
      </button>
      <button @click="submit" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse ml-1">
        Next
        <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2" />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SurveyQuestion9',
  components: {
  },
  data () {
    return {
      answer: this.$store.getters.getAnswers(14),
    };
  },
  methods: {
    submit() {
      this.save();
      this.$emit('changeIndex', 10);
    },
    gotoBack() {
      this.save();
      this.$emit('changeIndex', 8);
    },
    save() {
      let data = [];
      data['data'] = {
        question_id: 14,
        answer: this.answer
      };
      this.$store.dispatch('evaluateQuestion', data);
    }
  }
}
</script>

<style>
</style>