<template>
  <div class="grid grid-cols-1 content-center flex flex-col sm:flex-col-reverse">
    <h1 class="text-center md:h-24 text-4xl animate__animated animate__slideInDown">Did we meet your requirements with this delivery solution?</h1>
    <div class="text-center mt-6 md:h-68">
        <label class="w-8/12 md:w-6/12 hero-label animate__animated animate__slideInLeft" @dblclick="submit">
            <input type="radio" v-model="answer" class="hero-radio-input" value="Completely">
            <div class="hero-design"></div>
            <div class="hero-text">Completely</div>
        </label>
        <label class="w-8/12 md:w-6/12 hero-label animate__animated animate__slideInLeft" @dblclick="submit">
            <input type="radio" v-model="answer" class="hero-radio-input" value="Mostly">
            <div class="hero-design"></div>
            <div class="hero-text">Mostly</div>
        </label>
        <label class="w-8/12 md:w-6/12 hero-label animate__animated animate__slideInLeft" @dblclick="submit">
            <input type="radio" v-model="answer" class="hero-radio-input" value="Partially">
            <div class="hero-design"></div>
            <div class="hero-text">Partially</div>
        </label>
        
        <label class="w-8/12 md:w-6/12 hero-label animate__animated animate__slideInLeft" @dblclick="submit">
            <input type="radio" v-model="answer" class="hero-radio-input" value="Not at all">
            <div class="hero-design"></div>
            <div class="hero-text">Not at all</div>
        </label>
        <label class="w-8/12 md:w-6/12 hero-label animate__animated animate__slideInLeft">
            <input type="radio" v-model="answer" class="hero-radio-input" value="Other">
            <div class="hero-design"></div>
            <div class="hero-text">Other</div>
        </label>
        <input v-if="answer == 'Other'" v-model="otherAnswer" style="width: 80%" class="animate__animated animate__slideInLeft items-center appearance-none w-8/12 bg-transparent border-b border-primary w-full text-gray-700 mt-6 mr-3 py-1 px-2 leading-tight focus:outline-none" :class="{ 'border-red': error }" type="text">
        <p v-if="error" class="text-red">This field is required if selected other</p>
    </div>
    <div class="text-center mt-6">
      <button @click="gotoBack" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse mr-1">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2" />
        Back
      </button>
      <button @click="submit" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse ml-1">
        Next
        <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2" />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SurveyQuestion2',
  components: {
  },
  data () {
    return {
        answer : this.$store.getters.getAnswers(2),
        otherAnswer: this.$store.getters.getAnswers(3),
        error: false
    };
  },
  methods: {
    gotoBack() {
      this.save();
      this.$emit('changeIndex', 1);
    },
    submit() {
      if (this.answer == 5) {
        if (!this.otherAnswer) {
          this.error = true;
        }
      } else {
        this.otherAnswer = "";
      }

      if (!this.error) {
        this.save();
        this.$emit('changeIndex', 3);
      }
    },
    save() {
      let data = [];
      data['data'] = [
        {
          question_id: 2,
          answer: this.answer
        },
        {
          question_id: 3,
          answer: this.otherAnswer
        }
      ];
      this.$store.dispatch('evaluateQuestion', data);
    }
  },
  watch: {
    answerOption () {
      this.error = false;
    }
  }
}
</script>

<style>
.hero-label {
    display: flex;
    align-items: center;
    border-radius: 100px;
    padding: 6px 17px;
    cursor: pointer;
    transition: .3s;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    border: 1px solid #004346;
}
.hero-radio-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;
  }
  
  .hero-design {
    width: 22px;
    height: 22px;
    border-radius: 100px;
    background: #004346;
    position: relative;
  }
  
  .hero-design::before {
    content: '';
    display: inline-block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: #fff;
    transform: scale(1.1);
    transition: .3s;
    border: 1px solid #004346;
  }
  .hero-radio-input:checked+.hero-design::before {
    transform: scale(0);
    background: #fff;
  }
  
  .hero-text {
    margin-left: 15px;
    font-size: 20px;
    font-weight: 600;
    transition: .3s;
  }
  
  .hero-radio-input:checked~.hero-text {
    color: #004346;
  }
  .hero-radio-input:checked~.hero-label {
    background-color: #004346;
  }

  .hero-label-2 .hero-design::before {
    border: 1px solid #004346;
  }
</style>