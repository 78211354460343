import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import './index.css';
import 'animate.css';

import store from './store'

library.add(fas, far)

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(store)
    .use(VueAxios, axios)
    .mount('#app')
