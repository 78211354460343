<template>
    <div class="inline-flex flex-col-reverse md:flex-row">
      <StartSurvey v-if="currentIndex == 0" v-on:changeIndex="changeIndex" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <SurveyQuestion1 v-if="currentIndex == 1" v-on:changeIndex="changeIndex" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <SurveyQuestion2 v-if="currentIndex == 2" v-on:changeIndex="changeIndex" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <SurveyQuestion3 v-if="currentIndex == 3" v-on:changeIndex="changeIndex" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <SurveyQuestion4 v-if="currentIndex == 4" v-on:changeIndex="changeIndex" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <SurveyQuestion5 v-if="currentIndex == 5" v-on:changeIndex="changeIndex" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <SurveyQuestion6 v-if="currentIndex == 6" v-on:changeIndex="changeIndex" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <SurveyQuestion7 v-if="currentIndex == 7" v-on:changeIndex="changeIndex" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <SurveyQuestion8 v-if="currentIndex == 8" v-on:changeIndex="changeIndex" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <SurveyQuestion9 v-if="currentIndex == 9" v-on:changeIndex="changeIndex" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <SurveyQuestion10 v-if="currentIndex == 10" v-on:changeIndex="changeIndex" v-on:storeData="storeData" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <ThankYou v-if="currentIndex == 11" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <TokenError v-if="currentIndex == 12" class="md:w-6/12 mt-6 md:mt-0 p-3 md:p-0"/>
      <BackgroundImage class="md:w-6/12" />
  </div>
</template>
<script>
import BackgroundImage from './BackgroundImage'
import StartSurvey from './StartSurvey'
import SurveyQuestion1 from './SurveyQuestions/SurveyQuestion1'
import SurveyQuestion2 from './SurveyQuestions/SurveyQuestion2'
import SurveyQuestion3 from './SurveyQuestions/SurveyQuestion3'
import SurveyQuestion4 from './SurveyQuestions/SurveyQuestion4'
import SurveyQuestion5 from './SurveyQuestions/SurveyQuestion5'
import SurveyQuestion6 from './SurveyQuestions/SurveyQuestion6'
import SurveyQuestion7 from './SurveyQuestions/SurveyQuestion7'
import SurveyQuestion8 from './SurveyQuestions/SurveyQuestion8'
import SurveyQuestion9 from './SurveyQuestions/SurveyQuestion9'
import SurveyQuestion10 from './SurveyQuestions/SurveyQuestion10'
import ThankYou from './ThankYou'
import TokenError from './TokenError';
import axios from "axios";
export default {
   name: "SurveyQuestion",
   components: {
        BackgroundImage,
        StartSurvey,
        SurveyQuestion1,
        SurveyQuestion2,
        SurveyQuestion3,
        SurveyQuestion4,
        SurveyQuestion5,
        SurveyQuestion6,
        SurveyQuestion7,
        SurveyQuestion8,
        SurveyQuestion9,
        SurveyQuestion10,
        ThankYou,
        TokenError
   },
   data() {
       return {
           currentIndex: 0
       }
   },
   methods: {
       changeIndex(index) {
           this.currentIndex = index;
       },
       async storeData() {
           try {
            let config = {
                headers: {
                  "Accept": "application/json",
                }
            };
            let data = {
                token: this.$store.state.token,
                servey_data: this.$store.state.questions
            };
            const res = await axios.post(process.env.VUE_APP_EXE_API_URL + "v1/store-customer-survey", data, config);
            console.log(res.data);
            this.$store.dispatch('removeAnswers');
            this.currentIndex = 11;
        } catch (error) {
            console.log(error);
            this.currentIndex = 12;
        }
       }
   }
}
</script>