let mutations = {
    UPDATE_TOKEN (state, payload) {
        state.token = payload;
    },
    EVALUATE_QUESTION (state, payload) {
        var index = 0;

        if (Array.isArray(payload)) {
            for (var i in payload) {
                index = state.questions.findIndex((question) => question.question_id == payload[i].question_id);
                state.questions[index].answer = payload[i].answer;
                localStorage.setItem('question_id_' + payload[i].question_id, payload[i].answer);
            }
        } else {
            index = state.questions.findIndex((question) => question.question_id == payload.question_id);
            state.questions[index].answer = payload.answer;
            localStorage.setItem('question_id_' + payload.question_id, payload.answer);
        }
    },
    REMOVE_ANSWERS (state) {
        state.questions.map((question) => { 
            question.answer = "";
            localStorage.removeItem('question_id_' + question.question_id);
            return question;
        });
    }
};

export default mutations;