<template>
  <div class="grid grid-cols-1 content-center flex flex-col sm:flex-col-reverse">
    <h1 class="text-center md:h-24 text-4xl animate__animated animate__slideInDown">What is your company name? *</h1>
    <div class="text-center mt-6 md:h-68">
        <input ref="question" v-model="answer" v-on:keyup="pressEnter" class="focus items-center appearance-none w-10/12 md:w-8/12 bg-transparent border-b border-primary text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" :class="{ 'border-red-500': error }" type="text">
        <p v-if="error" class="text-red-500">Company Name is required</p>
    </div>
    <div class="text-center mt-6">
      <button @click="submit" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse">
        Next
        <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2" />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SurveyQuestion1',
  components: {
  },
  data () {
    return {
      answer: this.$store.getters.getAnswers(1),
      error: false,
    };
  },
  methods: {
    pressEnter() {
      this.error = false;
    },
    submit() {
      if (this.answer) {
        let data = [];
        data['data'] = {
          question_id: 1,
          answer: this.answer
        };
        this.$store.dispatch('evaluateQuestion', data);
        this.$emit('changeIndex', 2);
        this.error = false;
      } else {
        this.error = true;
      }
    }
  }
}
</script>

<style>
</style>