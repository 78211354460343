<template>
  <div class="grid grid-cols-1 content-center flex flex-col sm:flex-col-reverse">
    <h1 class="text-center text-4xl animate__animated animate__slideInDown">Please tell us a bit about your <br>Exetel experience.</h1>
    <h4 class="text-center text-xl animate__animated animate__slideInUp">We'd really appreciate it.</h4>
    <div class="text-center mt-6">
      <button @click="startSurvey" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse">
        Start
      </button>
    </div>
    <div class="text-center mt-6 animate__animated animate__fadeIn animate__slow">
      <font-awesome-icon :icon="['fas', 'clock']" />
      <span class="ml-2">Takes 3 min</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'StartSurvey',
  methods: {
    startSurvey() {
      this.$emit('changeIndex', 1);
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    
    if (urlParams.has('token')) {
      this.$store.dispatch('updateToken', urlParams.get('token'));
    }
  }
}
</script>

<style>
</style>