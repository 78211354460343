<template>
    <tr :key="label" class="border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700">
        <td class="px-6 py-4">{{ label }}</td>
        <td class="px-6 py-4" v-for="i in 5" :key="`${label + i}`">
            <label class="hero-label-2">
                <input v-model="answer" :name="option" type="radio" class="hero-radio-input" :value="i"/>
                <div class="hero-design"></div>
            </label>
        </td>
    </tr>
</template>
<script>
export default {
    name: 'RadioInputTableRaw',
    props: {
        label: String,
        option: String,
        questionId: {
            type: Number,
            default: 0
        }
    },
    components: {
    },
    data () {
        return {
            answer: this.answerGroup(this.$store.getters.getAnswers(this.questionId))
        }
    },
    methods: {
        answerGroup(answer) {
            if (answer == "Very Poor") {
                return 1;
            } else if (answer == "Poor") {
                return 2;
            } else if (answer == "Neutral") {
                return 3;
            } else if (answer == "Good") {
                return 4;
            } else if (answer == "Very Good") {
                return 5;
            }
            return 0;
        }
    }
}
</script>
<style>

</style>
