<template>
  <div class="grid grid-cols-1 content-center flex flex-col sm:flex-col-reverse">
    <h2 class="text-center md:h-24 text-4xl animate__animated animate__slideInDown">
        How was your experience with <br> the different aspects of this delivery project?
    </h2>
    <div class="text-center md:h-68 mt-6 relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Very Poor
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Poor
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Neutral
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Good
                    </th>
                    <th scope="col" class="px-6 py-3">
                        Very Good
                    </th>
                </tr>
            </thead>
            <tbody>
                <RadioInputTableRaw :questionId="6" ref="option1" label="Delivered to schedule" option="option1" />
                <RadioInputTableRaw :questionId="7" ref="option2" label="Communication" option="option2" />
                <RadioInputTableRaw :questionId="8" ref="option3" label="Planning and collaboration" option="option3" />
                <RadioInputTableRaw :questionId="9" ref="option4" label="Service testing and handover" option="option4" />
            </tbody>
        </table>
    </div>
    <div class="text-center mt-6">
      <button @click="gotoBack" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse mr-1">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2" />
        Back
      </button>
      <button @click="submit" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse ml-1">
        Next
        <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2" />
      </button>
    </div>
  </div>
</template>

<script>
import RadioInputTableRaw from '../RadioInputTableRaw'
export default {
  name: 'SurveyQuestion4',
  components: {
    RadioInputTableRaw
  },
  methods: {
    submit() {
        this.save();
        this.$emit('changeIndex', 5);
    },
    answerGroup(answer) {
      if (answer == 1) {
        return "Very Poor";
      } else if (answer == 2) {
        return "Poor";
      } else if (answer == 3) {
        return "Neutral";
      } else if (answer == 4) {
        return "Good";
      } else if (answer == 5) {
        return "Very Good";
      }
      return "";
    },
    gotoBack() {
      this.save();
      this.$emit('changeIndex', 3);
    },
    save() {
      let data = [];
        data['data'] = [
          {
            question_id: 6,
            answer: (this.$refs.option1) ? this.answerGroup(this.$refs.option1.answer) : ""
          },
          {
            question_id: 7,
            answer: (this.$refs.option2) ? this.answerGroup(this.$refs.option2.answer) : ""
          },
          {
            question_id: 8,
            answer: (this.$refs.option3) ? this.answerGroup(this.$refs.option3.answer) : ""
          },
          {
            question_id: 9,
            answer: (this.$refs.option4) ? this.answerGroup(this.$refs.option4.answer) : ""
          },
        ];
        this.$store.dispatch('evaluateQuestion', data);
    }
  },
}
</script>

<style>
</style>