let actions = {
    evaluateQuestion(context, payload) {
        context.commit('EVALUATE_QUESTION', payload.data);
    },
    updateToken(context, payload) {
        context.commit('UPDATE_TOKEN', payload);
    },
    removeAnswers(context) {
        context.commit('REMOVE_ANSWERS');
    }
};

export default actions;