<template>
  <div class="grid grid-cols-1 content-center flex flex-col sm:flex-col-reverse">
    <h1 class="text-center md:h-24 text-4xl animate__animated animate__slideInDown">
        How likely are you to 
        <br>recommend Exetel?
    </h1>
    <div class="content-center md:h-68 animate__animated animate__slideInLeft mx-auto mt-6 sm:w-10/12 md:w-10/12">
        <div class="flex justify-between">
            <font-awesome-icon :icon="[i <= answer ? 'fas' : 'far', 'star']"
                :prefix="i <= answer ? 'fas' : 'far'"
                class="h-8 animate__animated animate__slower" 
                :class="{'animate__shakeY': (isHovering && hoverIndex == i) }"
                v-for="i in 10" :key="i"
                @mouseover="hovering(i, true)"
                @mouseout="hovering(0, false)"
                @dblclick="doubleClick(i)"
                @click="selectAnwser(i)"
            />
        </div>
    </div>
    <div class="text-center mt-6">
      <button @click="gotoBack" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse mr-1">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2" />
        Back
      </button>
      <button @click="submit" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse ml-1">
        Next
        <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2" />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SurveyQuestion7',
  components: {
  },
  data () {
    return {
        answer: this.$store.getters.getAnswers(12),
        isHovering: false,
        hoverIndex: 0
    };
  },
  methods: {
    hovering(index, status) {
      this.hoverIndex = index;
      this.isHovering = status;
    },
    selectAnwser(value) {
        this.answer = value;
    },
    doubleClick(value) {
        this.answer = value;
        this.submit();
    },
    submit() {
      this.save();
      this.$emit('changeIndex', 8);
    },
    gotoBack() {
      this.save();
      this.$emit('changeIndex', 6);
    },
    save() {
      let data = [];
      data['data'] = {
        question_id: 12,
        answer: this.answer
      };
      this.$store.dispatch('evaluateQuestion', data);
    }
  }
}
</script>

<style>
  .start:hover {
    -webkit-animation: shake 1s;
    animation: shake 1s;
  }
</style>