<template>
  <div class="grid grid-cols-1 content-center flex flex-col sm:flex-col-reverse">
    <h1 class="text-center md:h-24 text-4xl animate__animated animate__slideInDown">
    Requirements such as project risk were 
        <br> well documented and well planned?
    </h1>
    <div class="text-center mt-6 md:h-68">
        <label class="w-8/12 md:w-6/12 hero-label animate__animated animate__slideInLeft" @dblclick="submit">
            <input type="radio" v-model="answer" class="hero-radio-input" value="Agree">
            <div class="hero-design"></div>
            <div class="hero-text">Agree</div>
        </label>
        <label class="w-8/12 md:w-6/12 hero-label animate__animated animate__slideInLeft" @dblclick="submit">
            <input type="radio" v-model="answer" class="hero-radio-input" value="Undecided">
            <div class="hero-design"></div>
            <div class="hero-text">Undecided</div>
        </label>
        
        <label class="w-8/12 md:w-6/12 hero-label animate__animated animate__slideInLeft" @dblclick="submit">
            <input type="radio" v-model="answer" class="hero-radio-input" value="Disagree">
            <div class="hero-design"></div>
            <div class="hero-text">Disagree</div>
        </label>
        
        <label class="w-8/12 md:w-6/12 hero-label animate__animated animate__slideInLeft" @dblclick="submit">
            <input type="radio" v-model="answer" class="hero-radio-input" value="Strongly disagree">
            <div class="hero-design"></div>
            <div class="hero-text">Strongly disagree</div>
        </label>
    </div>
    <div class="text-center mt-6">
      <button @click="gotoBack" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse mr-1">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="mr-2" />
        Back
      </button>
      <button @click="submit" class="bg-primary hover:bg-primary text-white font-bold py-2 px-4 animate__animated animate__pulse ml-1">
        Next
        <font-awesome-icon :icon="['fas', 'arrow-right']" class="ml-2" />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SurveyQuestion3',
  components: {
  },
  data () {
    return {
        answer : this.$store.getters.getAnswers(4),
    };
  },
  methods: {
    submit() {
      this.save();
      this.$emit('changeIndex', 4);
    },
    gotoBack() {
      this.save();
      this.$emit('changeIndex', 2);
    },
    save() {
      let data = [];
        data['data'] = {
          question_id: 4,
          answer: this.answer
        };
      this.$store.dispatch('evaluateQuestion', data);
    }
  },
}
</script>

<style>
.hero-label {
    display: flex;
    align-items: center;
    border-radius: 100px;
    padding: 6px 17px;
    cursor: pointer;
    transition: .3s;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    border: 1px solid #004346;
}
.hero-radio-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    z-index: -1;
  }
  
  .hero-design {
    width: 22px;
    height: 22px;
    border-radius: 100px;
    background: #004346;
    position: relative;
  }
  
  .hero-design::before {
    content: '';
    display: inline-block;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: #fff;
    transform: scale(1.1);
    transition: .3s;
    border: 1px solid #004346;
  }
  .hero-radio-input:checked+.hero-design::before {
    transform: scale(0);
    background: #fff;
  }
  
  .hero-text {
    margin-left: 15px;
    font-size: 20px;
    font-weight: 600;
    transition: .3s;
  }
  
  .hero-radio-input:checked~.hero-text {
    color: #004346;
  }
  .hero-radio-input:checked~.hero-label {
    background-color: #004346;
  }

  .hero-label-2 .hero-design::before {
    border: 1px solid #004346;
  }
</style>